declare var require: any
import { produce } from 'immer'
import React, { useState } from 'react'
import intl from 'react-intl-universal'
import { useMutation, useQuery } from '@apollo/client'
import { showModal } from '../utils/CustomModal'
import { getRelevantUserActions, isColorDark } from '../../utils/functions'
import { Chapter } from '../../graphql/schemas/chapter/Chapter'
import { createActionMutation } from '../../graphql/mutations/action/createAction'
import { duplicateActionMutation } from 'graphql/mutations/action/duplicateAction'
import { getCurrentSubscriptionQuery } from 'graphql/queries/user/getUser'
import { getAppStateQuery } from 'graphql/queries/app/getAppState'
import { Action, upsellAction } from 'graphql/schemas/action/Action'
import { client } from 'app'
import { getChaptersForCourseQuery, GetChaptersForCourseRes } from 'graphql/queries/chapter/getChaptersForCourse'
import { Button } from 'components/utils/Button'
import { showAlert } from 'components/utils/Alert'
import { Feature } from 'graphql/schemas/user/User'
import Lock from 'components/icons/Lock'
import { UpsellModal } from 'components/modals/UpsellModal'
import { track } from 'utils/track'
import { useNavigate } from 'react-router-dom'
import { history } from 'utils/history'
import { useActiveWorkspaceId } from 'hooks/useActiveWorkspaceId'
import { SendUserLockedFeatureEmailVars, sendUserLockedFeatureEmailMutation } from 'graphql/mutations/emails/sendUserLockedFeatureEmail'
import MagicStarsIcon from 'components/icons/MagicStars'

type NewActionModalProps = {
  chapter: Pick<Chapter, 'id'|'courseId'|'actions'|'assessmentMode'>|undefined;
  manualReviews?: boolean|null;
  certificate: string;
  features: Feature[];
  reopen: () => void;
  close: () => void;
  showMagic?: () => void;
  whiteLabelData?: any;
  magicActionCreationEnabled?: boolean;
}

const NewActionModal = ({ chapter, manualReviews, certificate, features, reopen, close, whiteLabelData, showMagic, magicActionCreationEnabled }: NewActionModalProps) => {
  const navigate = history.navigate || useNavigate()
  const activeWorkspaceId = useActiveWorkspaceId()

  const [contentType, setContentType] = useState('')
  const [userAction, setUserAction] = useState('')

  const { data: appData } = useQuery(getAppStateQuery)
  const [companyId, courseId] = appData?.appState.currentCompanyIdCourseId.split('-') || ['', '']
  const { data: chapterData } = useQuery<GetChaptersForCourseRes>(getChaptersForCourseQuery, {
    variables: { companyId, courseId, returnAllAssessments: true },
  })

  const [create] = useMutation(createActionMutation, {
    onCompleted: () => {
      track({
        event: 'Action Created',
        variables: {
          programId: courseId,
          sessionId: chapter?.id,
        },
      })
    }
  })
  const [duplicate] = useMutation(duplicateActionMutation) // tracking below where we have more data
  const [sendUserLockedFeatureEmail] = useMutation<{ sendUserLockedFeatureEmail: string }, SendUserLockedFeatureEmailVars>(sendUserLockedFeatureEmailMutation)

  const currentChapter = chapterData?.chapters.find(c => c.id === chapter?.id)
  const primaryColor = whiteLabelData?.primaryColor

  const _types = require('../../constants/contentTypes.json')

  const fillUserActions = (value: string) => {
    if (!chapter) return

    if (chapter.assessmentMode && value === 'paste') {
      return showAlert('Pasting actions is disabled in this session (assessment mode is active).', 'danger')
    }

    if (chapter.assessmentMode && value !== 'assessment') {
      return showAlert(intl.get('action_modal_assessment_mode'), 'danger')
    }

    if (value !== 'paste') {
      const relevantUserActions = getRelevantUserActions(value)
      setContentType(value)
      setUserAction(relevantUserActions[0])
    }
    else {
      setContentType('paste')
      setUserAction('paste')
    }

    return undefined
  }

  const createAction = (edit?: boolean) => {
    if (!chapter) return

    if (contentType === 'certificate' && certificate) {
      return showAlert(intl.get('certificate_exists_text'), 'danger')
    }

    if (upsellAction(contentType, features)) {
      const modal = showModal({
        title: intl.get('home_group_invite'),
        component: <UpsellModal type={contentType === 'assessment' ? 'upgrade_for_assessment_basic' : 'upgrade_for_certificate'} track={contentType === 'assessment' ? 'assessment_action' : 'certificate_action'} close={() => {
          modal.close()
          reopen()
        }} />,
        onlyContent: true,
        className: 'add-action-modal',
      })
      if (contentType === 'assessment') {
        sendUserLockedFeatureEmail({
          variables: {
            feature: 'feature-locked-assessment-basic',
            organizationId: activeWorkspaceId
           },
        })
      } else {
        sendUserLockedFeatureEmail({
          variables: {
            feature: 'feature-locked-add-digital-certificates',
            organizationId: activeWorkspaceId
           },
        })
      }
      close()
      return
    }

    close()

    if (userAction === 'paste') {
      if (!copiedAction) return

      duplicate({
        variables: {
          ...copiedAction,
          toCourseId: chapter.courseId,
          toChapterId: chapter.id,
          actionUpdate: {},
          duplicateRewards: true,
        },
        update: (cache, { data: { duplicateAction } }) => {
          const app: any = cache.readQuery({ query: getAppStateQuery })
          const user: any = cache.readQuery({
            query: getCurrentSubscriptionQuery,
            variables: {
              userId: app.appState.loggedInAs.uid,
            },
          })
          const cc = user.user.profile.currentSubscription
          const [companyId, courseId] = cc && cc.split('-') || ['', '']
          const data: any = cache.readQuery({
            query: getChaptersForCourseQuery,
            variables: {
              companyId,
              courseId: chapter.courseId,
              returnAllAssessments: true,
            },
          })
          if (!data) return
          const newAction = produce(duplicateAction as Action, (draft) => {
            draft.order = chapter.actions.length + 1
          })
          cache.writeQuery({
            query: getChaptersForCourseQuery,
            variables: {
              companyId,
              courseId: chapter.courseId,
              returnAllAssessments: true,
            },
            data: produce(data, (draft) => {
              const index = draft.chapters.findIndex(c => c.id === chapter.id)
              if (index !== -1) draft.chapters[index].actions = draft.chapters[index].actions.concat(newAction)
            }),
          })

          track({
            event: 'Action Duplicated',
            variables: {
              originalProgramId: copiedAction.fromCourseId,
              programId: courseId,
              originalSessionId: copiedAction.fromChapterId,
              sessionId: chapter.id,
              originalActionId: copiedAction.fromActionId,
            },
          })

          if (edit) {
            navigate(`/learn/${chapter.id}/${duplicateAction.id}`, {replace: true})
          }
          else {
            reopen()
          }
        },
      })
    }
    else {
      let newAction = {
        contentType,
        userAction,
        title: intl.get(`action_${contentType}_title`) || `${contentType} action title`,
        order: (currentChapter?.actions.length || 0) + 1,
        interactionTime: 1,
      }

      if (contentType === 'word_puzzle') {
        newAction['lives'] = 5
      }

      create({
        variables: {
          chapterId: chapter.id,
          courseId: chapter.courseId,
          actionCreate: newAction,
        },
        update: async (proxy, { data: { createAction } }) => {
          const { data } = await client.query({
            query: getChaptersForCourseQuery,
            variables: {
              companyId,
              courseId: chapter.courseId,
              returnAllAssessments: true,
            },
          })
          if (!data) return
          const newAction = produce(createAction as Action, (draft) => {
            draft.order = (currentChapter?.actions.length || 0) + 1
          })
          proxy.writeQuery({
            query: getChaptersForCourseQuery,
            variables: {
              companyId,
              courseId: chapter.courseId,
              returnAllAssessments: true,
            },
            data: produce(data, (draft) => {
              const index = draft.chapters.findIndex(c => c.id === chapter.id)
              if (index !== -1) draft.chapters[index].actions = draft.chapters[index].actions.concat(newAction)
            }),
          })

          // setIsMounted(false)
          if (edit) {
            navigate(`/learn/${chapter.id}/${createAction.id}`)
          }
          else {
            showAlert(intl.get('action_successfully_created'), 'success')
            reopen()
          }
        },
      })
    }
  }

  const tooltip = 'You have to copy an Action first.'
  const copiedLSAction = localStorage.getItem('copiedAction')
  const copiedAction: { fromCourseId: string, fromChapterId: string, fromActionId: string } = copiedLSAction && JSON.parse(copiedLSAction) || false

  let extension = '.png'
  if (['image', 'multiple_choice', 'text', 'upload', 'word_puzzle'].includes(contentType)) {
    extension = '.svg'
  }

  return (
    <>
      <div className="flex justify-between mb-20 md:mb-0 md:mt-4">
        <div id="select-action-type" className="flex-1 grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-4 mr-0 md:mr-4">
          {_types.map((button: any, i) => {
            if (!button || manualReviews && button['contentType'] === 'assessment') return

            const color = contentType === button['contentType'] ? 'white' : button['color']
            const bgcolor = contentType === button['contentType'] ? button['color'] : ''
            const upsell = upsellAction(button['contentType'], features)

            if (button['contentType'] === 'word_puzzle' && !features?.includes('action-word_puzzle')) return

            const pasteEnabled = (button['contentType'] !== 'paste' || (button['contentType'] === 'paste' && copiedAction))

            return (
              <div
                key={`action${i}`}
                id={`${button['contentType']}-button-type`}
                className={`action-contenttype-block py-2 px-4 w-full flex h-unset items-center ${button['contentType']} ${contentType === button['contentType'] ? 'selected' : ''} ${!pasteEnabled ? 'disabled' : ''}`}
                style={{
                  color,
                  backgroundColor: bgcolor,
                  border: `solid 1px ${button['color']}`,
                }}
                onClick={e => {
                  pasteEnabled && fillUserActions(button['contentType'])
                  if (upsell && (button['contentType'].includes('certificate') || button['contentType'].includes('assessment'))) {
                    const modal = showModal({
                      title: intl.get('home_group_invite'),
                      component: <UpsellModal type={button['contentType'] === 'assessment' ? 'upgrade_for_assessment_basic' : 'upgrade_for_certificate'} track={button['contentType'] === 'assessment' ? 'assessment_action' : 'certificate_action'} close={() => {
                        modal.close()
                        reopen()
                      }} />,
                      onlyContent: true,
                      className: 'add-action-modal',
                    })
                    if (button['contentType'] === 'assessment') {
                      sendUserLockedFeatureEmail({
                        variables: {
                          feature: 'feature-locked-assessment-basic',
                          organizationId: activeWorkspaceId
                         },
                      })
                    } else {
                      sendUserLockedFeatureEmail({
                        variables: {
                          feature: 'feature-locked-add-digital-certificates',
                          organizationId: activeWorkspaceId
                         },
                      })
                    }
                    close()
                  }
                }}
                title={button['contentType'] === 'paste' && 'You have to copy an action first' || ''}>
                <div className="flex items-center">
                  <div className="action-contenttype-icon inline mr-4 text-3xl h-8"><span className={`icon-${button['icon']}`} /></div>
                  <div className="text-sm text-left">
                    {button['contentType'] === 'word_puzzle' && <div className="uppercase font-medium text-sm">{intl.get('global_new')}</div>}

                    {intl.get(`action_${button['contentType']}`)}

                    {upsell && <Lock className={`ml-2 w-3 h-3 ${contentType === button['contentType'] ? 'text-white' : 'text-gray-600'} inline-block`} />}
                  </div>
                </div>
              </div>
            )
          })}
        </div>

        <div className="border border-gray-300 hidden md:block" style={{ width: '341px', height: '605px' }}>
          <img src={`/images/screenshots/${contentType !== 'paste' && contentType !== '' ? contentType : 'example-placeholder'}${extension}`} className="w-full h-full object-cover" />
        </div>
      </div>

      <div className="mt-4 text-right flex items-center justify-end fixed bottom-0 left-0 right-0 md:relative space-x-4">
        {(showMagic && magicActionCreationEnabled) && <Button
          text={''}
          type="magic"
          className="px-4 md:py-1.5"
          onClick={showMagic}
          svgIcon={<MagicStarsIcon />}
        />}
        <Button
          text={intl.get('action_modal_button_add')}
          onClick={() => createAction()} disabled={!contentType}
        />

        <Button
          type="primary"
          style={primaryColor ? { '--primary-color': primaryColor } as React.CSSProperties : {}}
          className={`${primaryColor && 'bg-[var(--primary-color)] border-[var(--primary-color)] ring-[var(--primary-color)] hover:bg-[var(--primary-color)] hover:border-[var(--primary-color)] hover:ring-[var(--primary-color)]'} ${isColorDark(primaryColor) && 'text-zinc-50'}`}
          text={intl.get('action_modal_button_add_edit')}
          onClick={() => createAction(true)} disabled={!contentType}
          id="add-and-edit-button"
        />
      </div>
    </>
  )
}

export default NewActionModal
