import { archiveCourse } from 'actions/courses_actions'
import { changeCurrentCourse } from 'actions/users_actions'
import { CompletedTag, NewTag, RedoAssessments, UpdatedTag } from 'components/navbar/Navbar'
import { Button } from 'components/utils/Button'
import { showRetakeAssessmentsTag } from 'graphql/schemas/action/Action'
import { Company, subsDistributionParamsFactory } from 'graphql/schemas/company/Company'
import { Course, isCourseExpired } from 'graphql/schemas/course/Course'
import { UserProgress } from 'graphql/schemas/user/UserProgress'
import React, { useState } from 'react'
import { filterCompaniesWithDueDate, isColorDark, stringSlicer } from 'utils/functions'
import intl from 'react-intl-universal'
import { showAssessmentResults } from 'components/sliders/Assessments'
import { history } from 'utils/history'
import CalendarDay from 'components/icons/CalendarDays'

type CompanyRadioPickerProps = {
  companies: Company[]
  progresses: (UserProgress | null)[] | undefined
  userId: string
  course: Course
  isExpired?: boolean
  selectedCompany: React.MutableRefObject<Company | undefined>
  isGnowbeLearn?: boolean
  bundleId?: string | null
  onClose: () => void
  archive?: boolean
  chat?: boolean
  isShowAssessmentResults?: boolean
  isEditor?: boolean
  whiteLabelPrimaryColor: string | null
  whiteLabelAccentColor?: string | null
}

export const CompanyRadioPicker = ({companies, progresses, userId, course, isExpired, selectedCompany, isGnowbeLearn = false, bundleId, onClose, archive, chat, isShowAssessmentResults, isEditor, whiteLabelPrimaryColor, whiteLabelAccentColor}: CompanyRadioPickerProps) => {
  const [selectedCompanyId, setSelectedCompanyId] = useState('')
  const companiesWithDueDates = filterCompaniesWithDueDate(companies, course.id)

  const customStyle = {
    ...(whiteLabelPrimaryColor ? {'--primary-color': whiteLabelPrimaryColor } as React.CSSProperties : {}),
    ...(whiteLabelAccentColor ? {'--accent-color': whiteLabelAccentColor } as React.CSSProperties : {})
  }

  const companyList = companies.slice().sort((a, b) => {
      // Check if either groupName is "Sandbox"
      if (a.groupName === "Gnowbe Sandbox") return 1 // Move "Gnowbe Sandbox" to the end
      if (b.groupName === "Gnowbe Sandbox") return -1 // Move "Gnowbe Sandbox" to the end
    
      // If both groupNames exist, compare them
      if (a.groupName && b.groupName) {
        return a.groupName.localeCompare(b.groupName)
      }
    
      // Fallback to comparing names
      return a.name.localeCompare(b.name)
    })

  return(
    <div style={customStyle} className="text-deepgray">
      {!archive && !chat  && !isShowAssessmentResults
        ? <p>{intl.get('group_select_program_modal_description')}</p>
        : archive && !chat  && !isShowAssessmentResults
          ? <p>{intl.get('group_select_program_modal_archive')}</p>
          : !archive && !chat && isShowAssessmentResults
            ? <p>{intl.get('group_select_program_modal_assessment')}</p>
            : <p>{intl.get('group_select_program_modal_chat_with_curator')}</p>
      }
      <hr />
      <div className="space-y-2 mx-4 mt-2">
        {companyList.map((c, i) => {
          const progress = progresses?.find(p => p?.id === `${userId}-${c.id}-${course.id}`)
          const courseDueDate = companiesWithDueDates.find(company => company?.id === c.id)?.subsDistributionParams[0]?.dueDate
          const dueDate = courseDueDate ? new Date(courseDueDate).toLocaleDateString() : ''

          const assessmentsCompleted = showRetakeAssessmentsTag({
            assessmentsCompleted: progress?.assessmentsCompleted || false,
            assessmentsGrade: progress?.assessmentsGrade || 0,
            passingGrade: course.passingGrade || 0,
            canRetakeActionAssessments: course.canRetakeActionAssessments || false,
            assessmentsRetakeNum: progress?.assessmentsRetakeNum || 0,
            maxActionAssessmentsRetakes: course.maxActionAssessmentsRetakes || 999,
          })

          const ps = progress?.startedAt || 0
          const timestamps = progress
            ? progress.timestamps
            : { createdAt: 0, createdAtTree: 0, updatedAt: 0, updatedAtTree: 0 }
          const courseStatus = bundleId
            ? <img src="/images/library-icon.svg" className="absolute left-0 top-0 ml-2 mt-2" />
            : !!assessmentsCompleted
              ? <RedoAssessments status={assessmentsCompleted} x='right-0' />
              : course.timestamps.createdAt > Math.max(timestamps.updatedAt, ps)
                ? <NewTag x='right-0' />
                : course.timestamps.updatedAt > Math.max(timestamps.updatedAt, ps)
                || course.timestamps.createdAtTree > Math.max(timestamps.updatedAtTree, ps)
                || course.timestamps.updatedAtTree > Math.max(timestamps.updatedAtTree, ps)
                  ? <UpdatedTag x='right-0' />
                  : isGnowbeLearn && (progress?.completion || 0) < (course.graduationGrade || 80)
                    ? <img src="/images/library-icon.svg" className="absolute left-0 top-0 ml-2 mt-2" />
                    : ''

          const companyRadioPickerStatus = (!course.hasAssessments || (course.hasAssessments && !assessmentsCompleted)) && (progress?.completion || 0) >= (course.graduationGrade || 80) && !courseStatus
          ? <CompletedTag x='right-0' />
          : courseStatus

          const distributionParams = c && course && c.subsDistributionParams.find(c => c.courseId === course.id) || subsDistributionParamsFactory()
          const isExpired = isCourseExpired({ absoluteDeadline: distributionParams.absoluteDeadline, startedAt: progress?.startedAt || 0, relativeDeadline: distributionParams.relativeDeadline })

          return (
            <div key={c.id} className="flex flex-row items-center relative">
              <input id={c.id} type="radio" name="company_picker" value={c.id} disabled={isExpired && !archive} className={`${whiteLabelAccentColor ? 'text-[var(--accent-color)] focus:ring-[var(--accent-color)]' : 'focus:ring-lake text-lake'} h-4 w-4 border-gray-300`} onChange={(e)=>{
                setSelectedCompanyId(e.target.value)
                selectedCompany.current = companies.find((c) => c.id === selectedCompanyId)
              }} />
              <label htmlFor={c.id} className={`ml-3 font-medium ${isExpired ? 'text-gray-400' : 'text-gray-700'}`}>
                {(!archive && !chat) && <span className='bg-gray-200 uppercase mr-2 px-2 py-0.5 text-xs rounded-xl font-medium w-12 inline-block text-center'>{progress?.completion || 0}%</span>}
                  <span className="py-0.5 text-deepgray">{(c.groupName && stringSlicer(c.groupName, 25)) || (c.name && stringSlicer(c.name, 25))}</span>
              </label>
              {(((progress?.completion || 0) <= (course.graduationGrade || 80)) && courseDueDate) && <CalendarDay className={`ml-3 w-4 h-4 ${courseDueDate > Date.now() ? 'fill-green-600': 'fill-coral'}`} tooltip={`${courseDueDate > Date.now() ? `Due date: ${dueDate}` : `Was due on: ${dueDate}`}`}/> }
              {!isExpired ? companyRadioPickerStatus : <span className="absolute right-0 uppercase ml-2 inline-block px-2 py-0.5 border border-lightgray bg-[#E3E4E5] text-medgray text-xs rounded-xl font-medium">{intl.get('course_expired_button')}</span>}
            </div>
          )
        })}
      </div>

      {!archive && !chat && !isShowAssessmentResults
        ? <div className="mt-8">
          <Button
            id="company-picker-start-now-course-button"
            text={intl.get('start_now_course')}
            type="primary"
            style={whiteLabelPrimaryColor ? { '--primary-color': whiteLabelPrimaryColor } : {}}
            className={`${whiteLabelPrimaryColor && 'bg-[var(--primary-color)] border-[var(--primary-color)] ring-[var(--primary-color)] hover:bg-[var(--primary-color)] hover:border-[var(--primary-color)] hover:ring-[var(--primary-color)]'} ${isColorDark(whiteLabelPrimaryColor) && 'text-zinc-50'}`}
            fullWidth={true}
            onClick={() => {
              if (!selectedCompanyId) return

              changeCurrentCourse({ courseKey: { companyId: selectedCompanyId, courseId: course.id } })
              onClose()
            }}
          />
        </div>
        : archive && !chat && !isShowAssessmentResults
          ? <div className="mt-8">
            <Button
              text={intl.get('archive_course')}
              type="danger"
              fullWidth={true}
              onClick={() => {
                const subId = `${selectedCompanyId}-${course.id}`
                if (!selectedCompanyId) return

                archiveCourse(subId, true)
                onClose()
              }}
            />
          </div>
          : !archive && !chat && isShowAssessmentResults
              ? <div className="mt-8">
              <Button
                text={intl.get('show_assessment_grade')}
                type="primary"
                style={whiteLabelPrimaryColor ? { '--primary-color': whiteLabelPrimaryColor } : {}}
                className={`${whiteLabelPrimaryColor && 'bg-[var(--primary-color)] border-[var(--primary-color)] ring-[var(--primary-color)] hover:bg-[var(--primary-color)] hover:border-[var(--primary-color)] hover:ring-[var(--primary-color)]'} ${isColorDark(whiteLabelPrimaryColor) && 'text-zinc-50'}`}
                fullWidth={true}
                onClick={() => {
                  if (!selectedCompanyId || !isEditor) return
                  showAssessmentResults({ companyId: selectedCompanyId, course, isEditor, userId, skipProgress: true })
                  onClose()
                }}
              />
            </div>
          : <div className="mt-8">
              <Button
                text={intl.get('select_and_chat')}
                type="primary"
                fullWidth={true}
                style={whiteLabelPrimaryColor ? { '--primary-color': whiteLabelPrimaryColor } : {}}
                className={`${whiteLabelPrimaryColor && 'bg-[var(--primary-color)] border-[var(--primary-color)] ring-[var(--primary-color)] hover:bg-[var(--primary-color)] hover:border-[var(--primary-color)] hover:ring-[var(--primary-color)]'} ${isColorDark(whiteLabelPrimaryColor) && 'text-zinc-50'}`}
                onClick={() => {
                  history.navigate && history.navigate(`/chat/${selectedCompanyId}-${course.id}-${userId}`)
                  onClose()
                }}
              />
            </div>
          }
    </div>
  )
}