import { produce } from 'immer'
import React, { useEffect } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { client, updateAppCache } from 'app'
import { augmentName } from 'utils/utils'
import { updateChapterMutation } from 'graphql/mutations/chapter/updateChapter'
import { getAppStateQuery } from 'graphql/queries/app/getAppState'
import { GetChaptersForCourseRes, GetChaptersForCourseVars, getChaptersForCourseQuery } from 'graphql/queries/chapter/getChaptersForCourse'
import { GetEngagementsForSubscriptionRes, GetEngagementsForSubscriptionVars, getEngagementsForSubscriptionQuery } from 'graphql/queries/user/getEngagementsForSubscription'
import { GetCurrentSubscriptionRes, GetCurrentSubscriptionVars, getCurrentSubscriptionQuery, GetUserAndActiveCourseResponse, getUserAndActiveCourseQuery } from 'graphql/queries/user/getUser'
import { AppState } from 'graphql/schemas/app/State'
import { ChapterItem, ChapterItemLoader } from './ChapterListItem'
import { isChapterLocked } from 'graphql/schemas/chapter/Chapter'
import { MiniLoader } from 'components/utils/MiniLoader'
import { track } from 'utils/track'
import { ListenChaptersForCourseChangesRes, listenChaptersForCourseChangesListener } from 'graphql/subscriptions/chapter/listenChaptersForCourseChanges'
import { slog } from 'utils/dateFunctions'
import { getCurrentChapterId } from 'pages/Learn'
import { useNavigate, useParams } from 'react-router-dom'
import intl from 'react-intl-universal'

const PlaceholderChapter = ({ order } : { order: number }) => {

  return (
    <div className="flex cursor-pointer group text-left w-full items-center border-l-4 border-magic bg-magic text-white h-[72px]">
        <div className="flex flex-1 items-center mx-3 h-">
          <div className="w-4">
            <span className="font-bold cursor-grab text-white">
                <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M4 14C4 15.1 3.1 16 2 16C0.9 16 0 15.1 0 14C0 12.9 0.9 12 2 12C3.1 12 4 12.9 4 14ZM2 6C0.9 6 0 6.9 0 8C0 9.1 0.9 10 2 10C3.1 10 4 9.1 4 8C4 6.9 3.1 6 2 6ZM2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0ZM8 4C9.1 4 10 3.1 10 2C10 0.9 9.1 0 8 0C6.9 0 6 0.9 6 2C6 3.1 6.9 4 8 4ZM8 6C6.9 6 6 6.9 6 8C6 9.1 6.9 10 8 10C9.1 10 10 9.1 10 8C10 6.9 9.1 6 8 6ZM8 12C6.9 12 6 12.9 6 14C6 15.1 6.9 16 8 16C9.1 16 10 15.1 10 14C10 12.9 9.1 12 8 12Z"
                    fill="#FFFFFF"/>
                </svg>
              </span>
          </div>

          <div className="flex flex-1 ml-4 mt-4 mb-3 items-center">
            <div className="flex-1">
              <div className="text-white uppercase tracking-wider text-sm font-medium">
                {intl.get('session_number', { 0: order })}
              </div>
              <div className="font-bold text-xs line-clamp-4 break-words text-white">
                {intl.get('magic_session_being_created')}
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}


export const ChapterList = ({ isEditor, chapterId, mobile, showPlaceholderAction, showPlaceholderChapter }: { isEditor: boolean, chapterId: string, mobile?: boolean, showPlaceholderAction?: boolean, showPlaceholderChapter?: boolean }) => {
  const params = useParams()
  const navigate = useNavigate()

  const { data: appData } = useQuery<AppState>(getAppStateQuery)
  const [companyId, courseId] = appData?.appState.currentCompanyIdCourseId.split('-') || ['', '']

  const { data: subData } = useQuery<GetCurrentSubscriptionRes, GetCurrentSubscriptionVars>(getCurrentSubscriptionQuery, {
    skip: !appData,
    variables: { userId: appData!.appState.loggedInAs.uid },
  })

  const { data: userData } = useQuery<GetUserAndActiveCourseResponse>(getUserAndActiveCourseQuery, {
    skip: !appData,
    variables: { companyId, courseId, userId: appData!.appState.loggedInAs.uid },
  })

  const { data: chapterData, subscribeToMore: subscribeToMoreChapters } = useQuery<GetChaptersForCourseRes, GetChaptersForCourseVars>(getChaptersForCourseQuery, {
    skip: !appData,
    variables: { companyId, courseId, returnAllAssessments: isEditor },
  })

  const { data: engagementData, refetch: refetchEngagementData } = useQuery<GetEngagementsForSubscriptionRes, GetEngagementsForSubscriptionVars>(getEngagementsForSubscriptionQuery, {
    skip: !appData,
    variables: { companyId, courseId, userId: appData!.appState.loggedInAs.uid || '' },
  })

  const [updateChapter] = useMutation(updateChapterMutation)

  const initiateChapterForCourseChangesSub = () => {
    const chaptersSub = subscribeToMoreChapters<ListenChaptersForCourseChangesRes>({
      document: listenChaptersForCourseChangesListener,
      variables: {
        companyId: userData?.subscription.companyId || '',
        courseId: userData?.subscription.courseId || '',
        returnAllAssessments: isEditor,
      },
      updateQuery: (prev, { subscriptionData }) => {
        const data = subscriptionData.data.listenChaptersForCourseChanges

        if (data.courseId && data.courseId !== userData?.subscription.courseId || data.chapter && data.chapter.courseId !== userData?.subscription.courseId) return prev

        if (data?.__typename === 'ChapterCreated') {
          slog.i('Session created.')

          const uc = produce(prev, (draft) => {
            const ci = draft?.chapters && draft.chapters.findIndex(c => c.id === data.chapter.id)
            if (ci !== -1) {
              draft.chapters[ci] = data.chapter
            }
            else {
              draft.chapters.push(data.chapter)
            }
          })

          if (data.chapter.id === currentChapterId) {
            setTimeout(() => {
              navigate(`/learn/${data.chapter.id}`)
            }, 0)
          }

          if (appData?.appState.isImportingPpt) {
            updateAppCache('isImportingPpt', false)
          }

          return uc
        }

        if (data?.__typename === 'ChapterOrderUpdated') {
          slog.i('Session reordered.')
          const uc = produce(prev, (draft) => {
            const ci = draft.chapters.findIndex(c => c.id === data.id)
            if (ci === -1) return

            draft.chapters[ci].order = data.order
          })

          return uc
        }

        if (data?.__typename === 'ChapterRemoved') {
          slog.i('Session removed.')
          return produce(prev, (draft) => {
            draft.chapters = draft.chapters.filter(c => c.id !== data.id)
          })
        }

        if (data?.__typename === 'ActionOrderUpdated') {
          slog.i('Action reordered.')
          const uc = produce(prev, (draft) => {
            const ci = draft.chapters.findIndex(c => c.id === data.chapterId)
            if (ci === -1) return
            const ai = draft.chapters[ci].actions.findIndex(a => a.id === data.id)
            if (ai === -1) return

            draft.chapters[ci].actions[ai].order = data.order
          })

          return uc
        }

        return prev
      },
    })
    window['subs'] = window['subs'].concat(chaptersSub)
  }

  useEffect(() => {
    const chapterList = document.querySelector('.chapter-list-container-new')
    const activeChapter: HTMLElement|null = document.querySelector('.active-chapter')

    if (!chapterList || !activeChapter) return

    chapterList.scrollTop = activeChapter.offsetTop - 500
  }, [])

  useEffect(() => {
    setTimeout(() => {
      refetchEngagementData()
    }, 1000)
  }, [])

  useEffect (() => {
    if (!userData?.subscription.company || !subData || !chapterData) return
      initiateChapterForCourseChangesSub()

  }
  ,  [userData, subData])

  if (!appData || !chapterData || !engagementData || !userData) {
    return <div>loading</div>
  }

  const currentChapterId = getCurrentChapterId({
    courseId,
    chapters: chapterData?.chapters || [],
    chaptersUnlocked: userData?.subscription.course?.chaptersUnlocked || 'unlocked',
    currentDay: userData?.subscription.progress?.currentDay || 0,
    isEditing: appData?.appState.isEditing || false,
    isEditor: subData?.user.editorCourses.includes(courseId) || false,
    matchChapterId: params.chapterId,
    stateChapterId: appData?.appState.activeChapterId || '',
  })

  const onSortEnd = (o: { draggableId: string, destination: { index: number, droppableId: string }, source: { index: number, droppableId: string }}) => {
    const oldOrder = o.source.index + 1
    const newOrder = (o.destination?.index || 0) + 1
    const chapter = chapterData.chapters.find(c => c.id === o.draggableId)
    if (!chapter) return
    const nextChapterAfterReorder = chapterData.chapters
      .filter(c => !!c && c.id !== chapter.id && c.order >= newOrder)
      .slice()
      .sort((a, b) => a.order - b.order)[0]
    const prevChapterAfterReorder = chapterData.chapters
      .filter(c => !!c && c.id !== chapter.id && c.order <= newOrder)
      .slice()
      .sort((a, b) => b.order - a.order)[0]
    const newChapter = {
      order: newOrder,
    }
    if (newOrder < oldOrder) {
      // If moving up take new next chapter
      if (nextChapterAfterReorder) {
        if ((chapter.disabledUntilDay || 0) > (nextChapterAfterReorder.disabledUntilDay || 0)) {
          newChapter['disabledUntilDay'] = nextChapterAfterReorder.disabledUntilDay || 0
        }
      }
    }
    else {
      // If moving down take new previous chapter
      if (prevChapterAfterReorder) {
        if ((chapter.disabledUntilDay || 0) < (prevChapterAfterReorder.disabledUntilDay || 0)) {
          newChapter['disabledUntilDay'] = prevChapterAfterReorder.disabledUntilDay || 0
        }
      }
    }
    updateChapter({
      variables: {
        companyId,
        courseId: chapter.courseId,
        chapterId: chapter.id,
        chapterUpdate: newChapter,
      },
    })
    // optimistic update
    const d = client.readQuery({
      query: getChaptersForCourseQuery,
      variables: {
        companyId,
        courseId: chapter.courseId,
        returnAllAssessments: true,
      },
    })
    if (!d) return
    client.writeQuery({
      query: getChaptersForCourseQuery,
      variables: {
        companyId,
        courseId: chapter.courseId,
        returnAllAssessments: true,
      },
      // if I move down I care about chapters ABOVE newIndex
      // if I move up I care about chapters ABOVE oldIndex
      data: produce(d, (draft) => {
        draft.chapters = draft.chapters.map((c) => {
          const oldOrder = o.source.index + 1
          const destinationIndex = (o.destination?.index || 0)
          const newOrder = destinationIndex + 1
          if (o.source.index > destinationIndex) { // premikam GOR
            if (c.order <= oldOrder && c.order >= newOrder && c.id !== chapter.id) {
              return produce(c, (draft) => {
                draft.order += 1
              })
            }
            if (c.id === chapter.id) {
              return produce(c, (draft) => {
                draft.order = newOrder
              })
            }
            return c
          }
          if (destinationIndex > o.source.index) { // premikam DOL
            if (c.order >= oldOrder && c.order <= newOrder && c.id !== chapter.id) {
              return produce(c, (draft) => {
                draft.order -= 1
              })
            }
            if (c.id === chapter.id) {
              return produce(c, (draft) => {
                draft.order = newOrder
              })
            }
            return c
          }
          return c
        })
      }),
    })
    track({
      event: 'Session Reordered',
      variables: {
        programId: chapter.courseId,
      },
    })
  }

  const chapters = chapterData.chapters.slice().sort((a, b) => a.order - b.order)

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    paddingBottom: 1,
    ...draggableStyle,
  })

  const getListStyle = isDraggingOver => ({
  })

  const creatingAIChapterContent = userData.course.creatingAIChapterContent || {}

  return (
    <DragDropContext onDragEnd={onSortEnd}>
      <Droppable droppableId="droppable-chapters" ignoreContainerClipping={true}>
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className="divide-y divide-gray-200"
            style={getListStyle(snapshot.isDraggingOver)}>
            {chapters.map((chapter, index) => {
              // const study = studyData?.study.find((s) => s.chapterId === chapter.id)
              const engagement = engagementData.engagement.find(e => e.chapterId === chapter.id)
              const locked = isChapterLocked({ chapter, currentDay: userData.subscription.progress?.currentDay || 1, mode: userData.course.chaptersUnlocked })

              const isChapterCreating = creatingAIChapterContent[chapter.id] || false;
              if (userData.course.creatingAIContent && (isChapterCreating || !(chapter.id in creatingAIChapterContent))) return

              return (
                <Draggable
                  key={chapter.id}
                  draggableId={chapter.id}
                  index={index}
                  isDragDisabled={!appData.appState.isEditing}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps} // move to child component if you want a dedicated drag handle
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style,
                      )}>
                      <ChapterItem
                        key={chapter.id}
                        id={chapter.id}
                        courseId={chapter.courseId}
                        active={chapter.id === chapterId}
                        completion={engagement?.engagement || 0}
                        locked={locked}
                        order={chapter.order}
                        title={augmentName(chapter.title)(subData?.user)}
                        isEditing={appData.appState.isEditing}
                        isLast={index === chapters.length - 1}
                        streak={userData.course.chaptersUnlocked === 'streak'}
                        showPlaceholderAction={showPlaceholderAction}
                      />
                    </div>
                  )}
                </Draggable>
              )
            })}
            {userData.course.creatingAIContent && <ChapterItemLoader isEditing={appData.appState.isEditing} isLast={true}/>}

            {showPlaceholderChapter && <PlaceholderChapter order={chapters.length + 1}/>}

            {appData.appState.isImportingPpt && (
              <div className="flex justify-center mt-8">
                <MiniLoader />
              </div>
            )}

            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}
