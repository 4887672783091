import { Company } from 'graphql/schemas/company/Company'
import { OrganizationBasic } from '../organization/Organization'
import { UserBasic } from '../user/User'

export type Course = {
  id: string;
  title: string;
  curatorSignatureDisabled: boolean;
  curatorChatEnabled: boolean;
  allowUnlimitedDaySkipping: boolean;
  chaptersUnlocked: ChaptersUnlocked;
  tags: string[];
  canRetakeActionAssessments: boolean;
  maxActionAssessmentsRetakes: number;
  passingGrade: number;
  graduationGrade: number;
  workbookDisabled: boolean;
  allowMediaDownload: boolean;
  denyContentCopy: boolean;
  availableToCompanies: string[];
  availableToOrganizations: string[];
  chaptersCount: number;
  actionsCount: number;
  interactionTime: number;
  isTemplate: boolean;
  createdAt: number;
  updatedAt: number;
  timestamps:  {
    createdAt: number;
    createdAtTree: number;
    updatedAt: number;
    updatedAtTree: number;
  }
  cpeHours: number;
  owner: CourseOwner;
  hasAssessments: boolean;

  certSealUrl: string|null;
  scormSettings: ScormSettings|null;
  type: CourseType;
  tagline: string|null;
  description: string|null;
  imageUrl: string|null;
  imageUrlAlt: string|null;
  curatorName: string|null;
  curatorTitle: string|null;
  curatorCompany: string|null;
  curatorBio: string|null;
  curatorPhotoUrl: string|null;
  category: string|null;
  categoryText: string|null;
  skills: string[]|null;
  difficulty: string|null;
  published: boolean|null;
  status: string|null;
  language: string|null;
  titleEnglish: string|null;
  parentCourseId: string|null;
  masterCourseId: string|null;
  testCompany: Company|null;
  showActionAssessmentsResultsPerAction: boolean|null;
  manualReviews: boolean|null;
  likesCount: number|null;
  translatedToLanguage: string;
  creatingAIContent: boolean|null;
  creatingAIChapterContent: {
    [key: string]: boolean;
  } | object | null;
  creatingAICourseImage: boolean|null;
  creatingAIDescription: boolean|null;
}

export type CourseBasic = {
  id: string;
  title: string;
  imageUrl?: string;
}

type CourseType = 'gnowbe'|'scorm'|null
export type ChaptersUnlocked = 'daily'|'streak'|'unlocked'
export type CourseDifficulty = 'basic'|'intermediate'|'advanced'|'universal'

export type CourseOwner = {
  type: 'organization'|'user';
  id: string;
  organization?: OrganizationBasic|null;
  user?: UserBasic|null;
}

export type ScormSettings = {
  uploadStatus: 'created'|'running'|'complete'|'error'|'unknown';
}

export const isCourseExpired = ({ absoluteDeadline, startedAt, relativeDeadline }: { absoluteDeadline: number|null, startedAt: number, relativeDeadline: number|null }) => {
  const now = Number(Date.now())
  if (absoluteDeadline && absoluteDeadline < now) return true
  if (relativeDeadline && startedAt && (startedAt + relativeDeadline * 24 * 3600 * 1000) < now) return true
  return false
}
