import React, { useEffect, useMemo, useRef } from 'react'
import intl from 'react-intl-universal'
import { LikeButton } from './LikeButton'
import { MoreButton } from './MoreButton'
import { changeCurrentCourse } from 'actions/users_actions'
import { Company, subsDistributionParamsFactory } from 'graphql/schemas/company/Company'
import { Course, isCourseExpired } from 'graphql/schemas/course/Course'
import { archiveCourse } from 'actions/courses_actions'
import { Button } from 'components/utils/Button'
import { CompletedTag, LanguageTag, NewTag, RedoAssessments, UpdatedTag } from 'components/navbar/Navbar'
import { showRetakeAssessmentsTag } from 'graphql/schemas/action/Action'
import { showModal } from 'components/utils/CustomModal'
import { UpsellModal } from 'components/modals/UpsellModal'
import { track, trackButton, trackButtonEnhanced } from 'utils/track'
import { useQuery } from '@apollo/client'
import { getCurrentSubscriptionQuery, GetCurrentSubscriptionRes, GetCurrentSubscriptionVars } from 'graphql/queries/user/getUser'
import { useAppState } from 'graphql/hooks/useAppState'
import ShareProgramTabs from 'components/utils/ShareProgramTabs'
import { getCourseCompanies, getCourseProgresses } from 'graphql/schemas/user/UserSubscription'
import classNames from 'classnames'
import { CompanyRadioPicker } from './CompanyRadioPicker'
import { isRtlLanguage, stringSlicer } from 'utils/functions'
import { BrowserRouter, useNavigate } from 'react-router-dom'
import CalendarDay from 'components/icons/CalendarDays'
import { getUserSubscriptions2Query, GetUserSubscriptions2Res, GetUserSubscriptionsVars } from 'graphql/queries/user/getUserSubscriptions'
import { AppState, getAppStateQuery } from 'graphql/queries/app/getAppState'
import { useWhiteLabel } from 'context/whiteLabel'
import { darken, lighten } from 'polished'
import MagicCreationInProgressBanner from 'components/MagicCreationInProgressBanner'


type CourseItemProps = {
  company: Company|null;
  course: Course&{companyId?: string}|null;
  userId: string;
  liked: boolean;
  paddingRight: boolean;
  isActive: boolean;
  isAgent: boolean;
  isEditor: boolean;
  isArchived?: boolean;
  isLocked?: boolean;
  isGnowbeLearn?: boolean;
  bundleId?: string|null;
  fixedWidth?: boolean;
  dontShowGroups?: boolean;
  sortedByCompletion?: boolean;
  isExplore?: boolean;
}

const labelRender = {
  '1.RedoAssessments': RedoAssessments,
  '2.CompletedTag': CompletedTag,
  '3.UpdatedTag': UpdatedTag,
  '4.NewTag': NewTag,
  '5.library-icon': () => <img src="/images/library-icon.svg" className="relative left-0 top-0 ml-2 mt-2" />,
};

export const CourseItem = ({ company, course, userId, liked, paddingRight, isActive, isEditor, isAgent, isArchived, isLocked, isGnowbeLearn, bundleId, fixedWidth, dontShowGroups, sortedByCompletion, isExplore }: CourseItemProps) => {
  if (!course || !company) return null

  const { data: appData } = useQuery<AppState>(getAppStateQuery)
  const { whiteLabelData } = useWhiteLabel()
  const navigate = useNavigate()

  const accentColor = whiteLabelData.accentColor
  const primaryColor = whiteLabelData.primaryColor


  if (!appData) {
    return null
  }
  const { data: subData } = useQuery<GetCurrentSubscriptionRes, GetCurrentSubscriptionVars>(getCurrentSubscriptionQuery, {
    skip: !appData,
    variables: { userId: appData!.appState.loggedInAs.uid },
    nextFetchPolicy: 'standby',
  })

  const { data: companiesData, loading } = useQuery<GetUserSubscriptions2Res, GetUserSubscriptionsVars>(getUserSubscriptions2Query, { variables: {
    userId,
    archived: isArchived || false,
  }, nextFetchPolicy: 'standby'})

  if (loading || !companiesData) {
    return null
  }

  const companies = useMemo(() => getCourseCompanies(companiesData, course.id), [course.id])
  const progresses = useMemo(() => getCourseProgresses(companiesData, course.id), [course.id])
  const progress = progresses?.find(p => p?.id === `${userId}-${company.id}-${course.id}`)
  const maxProgress = progresses?.sort((a, b) => (b?.completion || 0) - (a?.completion || 0))[0]?.completion || 0
  const selectedCompany = useRef<Company|undefined>(undefined)

  useEffect(() => {
    if (companies.length > 0) {
      selectedCompany.current = companies[1]
    }
  }, [companies])

  const canInvite = !isArchived && company && (company.peerInvitationMode === 'approval' || company.peerInvitationMode === 'open' || isAgent)
  const distributionParams = company && course && company.subsDistributionParams.find(c => c.courseId === course.id) || subsDistributionParamsFactory()
  const isExpired = isCourseExpired({ absoluteDeadline: distributionParams.absoluteDeadline, startedAt: progress?.startedAt || 0, relativeDeadline: distributionParams.relativeDeadline })
  const companyId = selectedCompany?.current?.id || course?.companyId || company?.id || ''
  const isStarted = progress?.completion && progress.completion > 0
  const isPublisher = subData?.user.roles.includes('publisher')
  const isIndependentEditor = subData?.user.roles.includes('independentEditor')
  const isEditorOfOpenCourse = subData?.user.editorCourses.includes(course.id)

  const displayCompaniesCn = classNames({
    'inline-block border border-solid  rounded-r-full rounded-l-full py-1 px-2.5 truncate': true,
    'border-coral-normal/30 bg-coral-normal/8 text-coral-normal': !primaryColor,
    'text-[var(--primary-darker-color)] border-[var(--primary-color)] bg-[var(--primary-lighter-color)]': primaryColor,
  })

  const openCourse = (e, type?) => {
    if (type === 'keyboard' && e.code !== 'Enter') return
    e.preventDefault()
    if (isArchived) {
      return navigate(`/details/${course.companyId}-${course.id}${bundleId ? `/bundle/${bundleId}` : ''}`)
    }
    if (isExplore) {
      return navigate(`/details/${company.id}-${course.id}${bundleId ? `/bundle/${bundleId}` : ''}`)
    }
    navigate(`/details/${course.id}${bundleId ? `/bundle/${bundleId}` : ''}`)

    track({
      event: 'Program Card Clicked',
      variables: {
        company_id: companyId,
        programId: course.id,
      }
    })
  }

  const changeCourse = (e) => {
    e.preventDefault()
    e.stopPropagation()

    if (companies.length === 1 && isExpired) return

    if (companies.length > 1 && !dontShowGroups) {
      const modal = showModal({
        title: intl.get('group_select_program_modal_title'),
        component: (
          <BrowserRouter>
            <CompanyRadioPicker companies={companies} progresses={progresses} userId={userId} course={course} selectedCompany={selectedCompany} isGnowbeLearn={isGnowbeLearn} bundleId={bundleId} onClose={() => {modal.close()}} whiteLabelPrimaryColor={whiteLabelData.primaryColor} whiteLabelAccentColor={whiteLabelData.accentColor} />
          </BrowserRouter>
        ),
        hideFooter: true,
        onHideModal: () => selectedCompany.current = companies[0],
        whiteLabelData,
      })
      return
    }

    if (isActive) {
      if (isStarted) {
        return navigate('/learn')
      }

      return navigate(`/details/${course.id}`)
    }

    if (companyId && course) {
      changeCurrentCourse({ courseKey: { companyId: company.id || companyId, courseId: course.id }, landing: isStarted || dontShowGroups ? '/learn' : `/details/${course.id}` })
    }
  }

  const unarchive = (e) => {
    e.preventDefault()
    e.stopPropagation()

    archiveCourse(`${course.companyId}-${course.id}`, false)
  }


  const courseCompanies = companies.sort((a, b) => (a.groupName && b.groupName && a.groupName.localeCompare(b.groupName)) || a.name.localeCompare(b.name)).map((c, i) => {
    const progress = progresses?.find(p => p?.id === `${userId}-${c.id}-${course.id}`)
    const assessmentsCompleted = showRetakeAssessmentsTag({
      assessmentsCompleted: progress?.assessmentsCompleted || false,
      assessmentsGrade: progress?.assessmentsGrade || 0,
      passingGrade: course.passingGrade || 0,
      canRetakeActionAssessments: course.canRetakeActionAssessments || false,
      assessmentsRetakeNum: progress?.assessmentsRetakeNum || 0,
      maxActionAssessmentsRetakes: course.maxActionAssessmentsRetakes || 999,
    })

    const ps = progress?.startedAt || 0

    const timestamps = progress
      ? progress.timestamps
      : { createdAt: 0, createdAtTree: 0, updatedAt: 0, updatedAtTree: 0 }

    const courseStatus = bundleId
      ? {name: '5.library-icon', status: ''}
      : !!assessmentsCompleted
        ? {name: '1.RedoAssessments', status: assessmentsCompleted}
        : course.timestamps.createdAt > Math.max(timestamps.updatedAt, ps)
          ? {name: '4.NewTag', status: ''}
          : course.timestamps.updatedAt > Math.max(timestamps.updatedAt, ps)
          || course.timestamps.createdAtTree > Math.max(timestamps.updatedAtTree, ps)
          || course.timestamps.updatedAtTree > Math.max(timestamps.updatedAtTree, ps)
            ? {name: '3.UpdatedTag', status: ''}
            : isGnowbeLearn && (progress?.completion || 0) < (course.graduationGrade || 80)
              ? {name: '5.library-icon', status: ''}
              : {name: '', status: ''}

    const companyRadioPickerStatus = (!course.hasAssessments || (course.hasAssessments && !assessmentsCompleted)) && (progress?.completion || 0) >= (course.graduationGrade || 80) && !courseStatus.name
        ? {name: '2.CompletedTag', status: ''}
        : courseStatus

      return companyRadioPickerStatus
  })

  const assessmentsCompleted = showRetakeAssessmentsTag({
    assessmentsCompleted: progress?.assessmentsCompleted || false,
    assessmentsGrade: progress?.assessmentsGrade || 0,
    passingGrade: course.passingGrade || 0,
    canRetakeActionAssessments: course.canRetakeActionAssessments || false,
    assessmentsRetakeNum: progress?.assessmentsRetakeNum || 0,
    maxActionAssessmentsRetakes: course.maxActionAssessmentsRetakes || 999,
  })

  const ps = progress?.startedAt || 0
  const timestamps = progress
    ? progress.timestamps
    : { createdAt: 0, createdAtTree: 0, updatedAt: 0, updatedAtTree: 0 }
  const courseStatusTemp = bundleId
    ? <img src="/images/library-icon.svg" className="absolute left-0 top-0 ml-2 mt-2" />
    : !!assessmentsCompleted
      ? <RedoAssessments p="relative" status={assessmentsCompleted} />
      : course.timestamps.createdAt > Math.max(timestamps.updatedAt, ps)
        ? <NewTag p="relative" />
        : course.timestamps.updatedAt > Math.max(timestamps.updatedAt, ps)
        || course.timestamps.createdAtTree > Math.max(timestamps.updatedAtTree, ps)
        || course.timestamps.updatedAtTree > Math.max(timestamps.updatedAtTree, ps)
          ? <UpdatedTag p="relative" />
          : isGnowbeLearn && (progress?.completion || 0) < (course.graduationGrade || 80)
            ? <img src="/images/library-icon.svg" className="absolute left-0 top-0 ml-2 mt-2" />
            : ''

    const courseStatus = (!course.hasAssessments || (course.hasAssessments && !!assessmentsCompleted)) && (progress?.completion || 0) >= (course.graduationGrade || 80)
    ? <CompletedTag x='right-0' p="relative" />
    : courseStatusTemp

  // group statuses by name, to be able to count how many Completed, New, Updated we have
  const courseCompaniesGrouped = courseCompanies.reduce((acc, obj) => {
    const name = obj.name
    const status = obj.status
    if (!acc[name]) {
      acc[name] = { count: 0 }
    }
    acc[name].count++
    acc[name].status = { status }
    return acc
  }, {})

  const courseCompaniesGrouped2 = Object.entries(courseCompaniesGrouped).map(([key, value]) => {
    return { name: key, count: value.count, status: value.status}
  });

  const showCompaniesGroupedLabel = courseCompaniesGrouped2.filter(el => el.name !== '').length > 1 ? 'false' : 'true'

  const highestCompletion = progresses?.reduce((prev, current) => {
    if (current) {
      if (!prev || (!!current.completion && (prev.completion === null || current.completion > prev.completion || (current.completion === prev.completion && current?.lastActiveAt < prev?.lastActiveAt)))) {
        return current
      } else {
        return prev
      }
    }
    return prev
  }, null)

  const lastActiveAt = progresses?.reduce((acc, current) => {
    if (current) {
      if (!acc || (!!current.lastActiveAt && (current.lastActiveAt > acc.lastActiveAt))) {
        return current
      } else {
        return acc
      }
    }
    return acc
  }, null);

  const highestCompletionCompany = companies?.find(c => c.id === highestCompletion?.id.split('-')[1])
  const lastActiveAtCompany = companies?.find(c => c.id === lastActiveAt?.id.split('-')[1])
  const canBeLiked = subData?.user.subscriptions.includes(`${company.id}-${course.id}`)
  const courseDueDate = company?.subsDistributionParams?.find(distrParams => distrParams.courseId === course.id)?.dueDate
  const dueDate = courseDueDate ? new Date(courseDueDate).toLocaleDateString() : ''

  const getLabels = React.useMemo(() => {
    return courseCompaniesGrouped2.filter(Boolean).map((el, index) => {
      const Component = labelRender[el.name];
      return Component && <Component p="relative" status={el?.status} count={el.count} y="top-2" key={index} showLabel={showCompaniesGroupedLabel} />;
    })
  }, [courseCompaniesGrouped2, showCompaniesGroupedLabel])

  const rtlDir: React.CSSProperties = isRtlLanguage(course.translatedToLanguage) ? { direction: 'rtl', textAlign: 'right' } : {}

  return (
    <div className={`relative ${fixedWidth ? 'w-60' : 'w-full'} ${paddingRight ? 'pr-4' : ''} bg-white/40 hover:bg-white/70 p-4 pb-1 rounded-md group ${company?.name.includes('test') ? `sandboxClassName` : `normalClassName`}`}>
      {course.creatingAIContent && <MagicCreationInProgressBanner isCourseItem />}
      <div className="relative">
        <div
          tabIndex={0}
          className={`group cursor-pointer w-full h-36 relative ${isExpired ? 'grayscale opacity-75' : ''} bg-gray-200 bg-center bg-no-repeat bg-cover rounded-md`}
          style={{ backgroundImage: `url(${course.imageUrl || ''})` }}
          onClick={openCourse}
          onKeyDown={e => openCourse(e, 'keyboard')}>
          <div className="absolute inset-0 bg-deepgray rounded-md opacity-0 group-hover:opacity-50 group-focus:opacity-50 transition" />

          {isExpired
            ? <button className="absolute text-white text-center w-20 h-5 flex justify-center inset-0 m-auto opacity-0 group-hover:opacity-100 group-focus:opacity-100 text-xl" onClick={changeCourse}>{intl.get('course_expired_button')}</button>
            : isArchived
              ? <button title={intl.get('unarchive')} className="absolute text-white border-2 border-white rounded-full w-12 h-12 flex items-center justify-center inset-0 m-auto icon icon-inbox-out-light opacity-0 group-hover:opacity-100 group-focus:opacity-100 text-xl font-bold shadow-md transition hover:bg-white hover:text-deepgray focus:outline-none focus:bg-white focus:text-deepgray" aria-label="Unarchive" onClick={unarchive} />
              : isLocked
                ? null
                : <button title={isStarted ? intl.get('dialog_notes_negative_text') : intl.get('start_learning_text')} className="absolute text-white border-2 border-white rounded-full w-12 h-12 pl-1 flex items-center justify-center inset-0 m-auto icon icon-play-light opacity-0 group-hover:opacity-100 group-focus:opacity-100 text-xl font-bold shadow-md transition hover:bg-white hover:text-deepgray focus:outline-none focus:bg-white focus:text-deepgray" aria-label="Continue learning" onClick={changeCourse} disabled={!!course.creatingAIContent} />
          }
        </div>

        <div className="absolute top-0 left-0">
          {!dontShowGroups
            ? <>{getLabels}</>
            : (!course.hasAssessments || (course.hasAssessments && !assessmentsCompleted)) && (progress?.completion || 0) >= (course.graduationGrade || 80) && !courseStatus
              ? <CompletedTag y="top-2" p="relative" />
              : courseStatus}
        </div>
        {course.translatedToLanguage && <div className="absolute bottom-0 right-0">
          <LanguageTag language={course.translatedToLanguage} y="bottom-2" />
        </div>}
        {(((progress?.completion || 0) <= (course.graduationGrade || 80)) && courseDueDate) && <div className="absolute bottom-2 left-1 ml-1 inline-block px-1 py-1 bg-white font-bold text-xs rounded-xl border border-[#4A4A4A]/[.32]" >
          <CalendarDay className={`h-4 w-4 ${courseDueDate > Date.now() ? 'fill-green-600': 'fill-coral'}`} tooltip={`${courseDueDate > Date.now() ? `Due date: ${dueDate}` : `Was due on: ${dueDate}`}`} />
        </div>}
      </div>

      <div className="pt-5">
        <div className="h-16">
          <div className="flex items-center justify-start max-h-12 line-clamp-2" title={course.title || 'Program'} style={rtlDir}>
            <button
              style={accentColor ? { '--accent-color': accentColor } as React.CSSProperties : rtlDir}
              className={`text-left leading-6 font-bold course-title text-deepgray line-clamp-2 ${accentColor ? `hover:text-[var(--accent-color)]` : 'hover:text-actions-multiple_choice'}`}
              onClick={openCourse}
              >
              {stringSlicer(course.title, fixedWidth ? 35 : 44) || 'Program'}
            </button>
          </div>

          {/* <div className="text-left text-sm mt-1 line-clamp-1">
            By: {course.curatorName}
          </div> */}
        </div>

        {isArchived && (
          <div className="mt-4">
            <Button
              className="text-coral bg-transparent"
              fullWidth={true}
              text={intl.get('unarchive')}
              onClick={unarchive}
            />
          </div>
        )}

        <div className="text-sm mt-2.5 text-actions-multiple_choice">
          {!dontShowGroups && (companies.length > 1
            ? <div className='flex'><div className={displayCompaniesCn}>{((company && sortedByCompletion) && stringSlicer(highestCompletionCompany?.name, 19)) || (company && stringSlicer(lastActiveAtCompany?.name, 19) || stringSlicer(company.groupName, 19) || stringSlicer(company.name, 19) || (selectedCompany.current?.groupName && stringSlicer(selectedCompany.current.groupName.length, 19))  || (selectedCompany.current?.name && stringSlicer(selectedCompany.current.name.length, 19)) || 'Loading groups...')}</div> {company && <div className={`${displayCompaniesCn} ml-1`}>+ {companies.length - 1}</div>}</div>
            : company ?
              <div className={displayCompaniesCn}>{stringSlicer(company.groupName, 19) || stringSlicer(company.name, 19)}</div>
            : companies[0]
                ? <div className={displayCompaniesCn}>{companies[0]?.groupName || companies[0]?.name}</div>
                : <div className={displayCompaniesCn}>Loading groups...</div>)
          }
        </div>

        {!isArchived && (companies.length <= 1 || dontShowGroups)&& (
          <div className="flex my-3 items-center">
            <div className="flex flex-1 h-2 mr-4 space-x-2 -mt-1">
              <div className="flex-1 relative rounded-md" style={{ backgroundColor: whiteLabelData.accentColor ? lighten(0.4, whiteLabelData.accentColor) : '#EEE8D9' }}>
                <span className="h-2 rounded-md inline-block absolute left-0" style={{ width: `${dontShowGroups ? progress?.completion || 0 : maxProgress}%`, backgroundColor: (dontShowGroups ? (progress?.completion || 0) : maxProgress) >= course.graduationGrade ? whiteLabelData.accentColor ? darken(0.2, whiteLabelData.accentColor) : '#1b585e' : whiteLabelData.accentColor ?? '#5ec3c5' }} />
              </div>

                <div className="text-sm -mt-1">
                  {dontShowGroups ? progress?.completion || 0 : maxProgress}%
                </div>
            </div>

            {!isExplore && companyId && course && (
              <MoreButton
                companyId={companyId}
                courseId={course.id}
                courseTitle={course.title || 'Program'}
                canInvite={!!canInvite && !isExpired}
                isEditor={isEditor}
                isAgent={isAgent}
                isSandbox={company?.companyType === 'test' || false}
                groupName={company?.groupName || company?.name || 'Unknown group'}
              />
            )}
          </div>
        )}
      </div>
      <div className={`border-t border-[#D9D9D9] pt-2 mb-2 ${companies.length > 1 ? 'mt-12' : ''}`}>
        <div className="flex justify-between h-10">
          {!isArchived && (
            <LikeButton
              companies={companies.length > 0 ? companies : [company]}
              companyId={companyId || ''}
              courseId={course.id}
              userId={userId}
              liked={liked}
              likesCount={course.likesCount}
              disabled={!canBeLiked}
            />
          )}
          {(isEditor || canInvite) &&
            <div className="my-auto">
              <Button
                text=""
                onClick={() => {
                  if (subData?.user.roles.includes('independentEditor') && !sessionStorage.getItem('share_upsell_shown')) {
                    sessionStorage.setItem('share_upsell_shown', 'true')
                    const modal = showModal({
                      title: intl.get('home_group_invite'),
                      component: <UpsellModal type="free" track="generic_starter" close={() => modal.close()} />,
                      onlyContent: true,
                      className: 'add-action-modal',
                      // whiteLabelData,
                    })
                    return
                  }

                  trackButton({
                    button: 'share_course',
                    on_screen: 'three_dots',
                  })

                  trackButtonEnhanced({
                    button: 'Share Program',
                    onScreen: 'Three Dots',
                  })

                  const modal = showModal({
                    title: intl.get('program_share_sandbox'),
                    component: (<>
                      <ShareProgramTabs canInvite={canInvite} company={company} course={course} isAgent={isAgent} isEditorOfOpenCourse={isEditorOfOpenCourse} isPublisher={isPublisher} isIndependentEditor={isIndependentEditor} close={() => modal.close()} />
                    </>),
                    secondaryButton: false,
                    primaryText: intl.get('global_close'),
                    hideFooter: true,
                    // whiteLabelData,
                  })
                }}
                className="flex items-center w-auto pl-4 pr-2 py-2 text-sm text-left border-none rounded-3xl bg-gray-100 hover:bg-gray-200 shadow-none"
                icon="icon-share-light text-base"
              />
            </div>
          }
        </div>
      </div>
    </div>
  )
}
