import React, { Suspense } from 'react'
import intl from 'react-intl-universal'
import Lightbox from 'react-image-lightbox'
import { useQuery } from '@apollo/client'
import { uploadFile, IMAGE_MIMETYPES, getSignedValue } from 'actions/files'
import { AppState, getAppStateQuery } from 'graphql/queries/app/getAppState'
import { showModal } from 'components/utils/CustomModal'
import { MiniLoader } from './MiniLoader'
import { showSave } from './SavingIndicator'
import 'react-image-lightbox/style.css'
import Video from '../action/types/Video'
import { GetOrganizationsRes, GetOrganizationsVars, getOrganizationsQuery } from 'graphql/queries/organization/getOrganziations'
import { useActiveWorkspaceId } from 'hooks/useActiveWorkspaceId'
import { isColorDark } from 'utils/functions'

type CoverEditableProps = {
  disabled: boolean;
  token: string;
  target: string;
  defaultImageUrl: string;
  defaultVideoUrl?: string;
  videoUrl_720p?: string;
  sessionVideo?: boolean;
  field: string;
  allowMediaDownload: boolean;
  isEditing: boolean;
  mimetypes?: string[];
  fullWidth?: boolean;
  update: (f: string, v: string) => void;
  courseId: string;
  showAiGenerateImage?: boolean;
  organizationId?: string;
  courseCover?: boolean;
  bgColor?: string;
  creatingAiImage?: boolean;
  creatingAiContent?: boolean;
}

type CoverEditableState = {
  value: string;
  hdVideoValue: string;
  isUploading: boolean;
  zoomed: boolean;
  realSize?: { width: number, height: number };
}

const Loading = () => {
  return (
    <div className="mt-4 py-7 text-center">
      Please wait while our Gnome loads the page...
    </div>
  )
}

class Editable extends React.PureComponent<CoverEditableProps, CoverEditableState> {
  private _cover
  private _isMounted = false

  constructor(props) {
    super(props)
    this.state = { value: props.defaultImageUrl || props.defaultVideoUrl || '', hdVideoValue: props.videoUrl_720p || '', isUploading: false, zoomed: false }
    this.handleRightClick = this.handleRightClick.bind(this)
    this.getImageSize = this.getImageSize.bind(this)
    this.toggleZoom = this.toggleZoom.bind(this)
  }

  componentDidMount() {
    this._isMounted = true
    this.getImageSize()
    this._cover && this._cover.addEventListener('contextmenu', this.handleRightClick)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.defaultImageUrl === this.props.defaultImageUrl) return
    if (prevProps.defaultImageUrl !== this.props.defaultImageUrl || prevProps.defaultVideoUrl !== this.props.defaultVideoUrl) {
      this.setState({ value: this.props.defaultImageUrl || this.props.defaultVideoUrl || '', hdVideoValue: this.props.videoUrl_720p || '' })
    }
    this.getImageSize()
  }

  componentWillUnmount() {
    this._isMounted = false
    this._cover && this._cover.removeEventListener('contextmenu', this.handleRightClick)
  }

  getImageSize(setState: boolean = true): { width: number, height: number } {
    const img = new Image()
    img.onload = () => {
      const height = img.height
      const width = img.width
      this._isMounted && setState && this.setState({ realSize: { height, width } })
      return { height, width }
    }
    img.src = this.props.defaultImageUrl
    return { width: 0, height: 0 }
  }

  handleRightClick(e: React.MouseEvent) {
    if (this.props.allowMediaDownload || this.props.isEditing) {
      return
    }
    e.preventDefault()
    return false
  }

  handlePhotoUpload(e: React.SyntheticEvent<any>, field: string) {
    if (!this.state.isUploading && !this.props.disabled) {
      this._isMounted && this.setState({ isUploading: true })
      uploadFile({ authToken: this.props.token, mimetypes: this.props.mimetypes || IMAGE_MIMETYPES, target: this.props.target, courseId: this.props.courseId, canGenerateAiImages: this.props.showAiGenerateImage, organizationId: this.props.organizationId })
        .then((data) => {
          showSave()
          const signedData = data.url ? getSignedValue(data.url) : ''
          this._isMounted && this.setState({ isUploading: false, value: signedData })
          if (data?.type?.match(/video/)) {
            this.props.update('videoUrl', data.url || '')
          }
          else {
            this.props.update(field, data.url || '')
          }
        })
        .catch((err) => {
          this._isMounted && this.setState({ isUploading: false })
        })
    }
  }

  handlePhotoDelete(e: React.SyntheticEvent<any>, field: string) {
    showModal({
      title: intl.get('global_are_you_sure_header'),
      content: !this.props.defaultVideoUrl ? intl.get('confirm_photo_delete_text') : intl.get('confirm_video_delete_text'),
      primaryText: !this.props.defaultVideoUrl ? intl.get('remove_photo_button') : 'Remove Video',
      primaryAction: () => {
        this.setState({ value: '' })
        this.props.update(field, '')
      },
      destructive: true,
    })
  }

  toggleZoom(e) {
    this._isMounted && this.setState({ zoomed: !this.state.zoomed })
  }

  render() {
    const { isEditing, field, disabled } = this.props
    const { value, hdVideoValue, isUploading } = this.state

    const signedValue = getSignedValue(value)
    const signedValue720 = getSignedValue(hdVideoValue)

    if (!isEditing) {
      return (
        <div className="flex justify-center mb-8">
          <div className={`image-holder ${!this.props.sessionVideo && 'max-h-[300px]'} ${signedValue && this.props.defaultVideoUrl ? 'video' : ''}`}>
            {signedValue
              ? this.props.defaultImageUrl
                ? <img
                    ref={ref => this._cover = ref}
                    className="image"
                    src={signedValue}
                    alt="Cover image"
                    onClick={() => this.setState({ zoomed: true })}
                  />
                : <Suspense fallback={<Loading />}>
                    <Video
                      videoUrl={signedValue}
                      hdVideoUrl={signedValue720}
                      allowMediaDownload={false}
                    />
                  </Suspense>
              : <div className="image" />
            }
          </div>

          {this.state.zoomed && (
            <Lightbox
              mainSrc={signedValue}
              onCloseRequest={() => this.setState({ zoomed: false })}
            />
          )}
        </div>
      )
    }

    return (
      <div>
        <div className="flex justify-center">
          <div className={`image-holder ${value && this.props.defaultVideoUrl ? 'video' : ''}`}>
            {isUploading
              ? <div className="flex w-[400px] h-[300px] items-center justify-center border border-team rounded-sm">
                  <MiniLoader />
                </div>
              : signedValue
                ? this.props.defaultImageUrl
                  ? <img
                      className="image editable border-solid"
                      src={signedValue ? signedValue : '/images/image-placeholder.jpg'}
                      onClick={e => this.handlePhotoUpload(e, field)}
                      alt="Upload photo or video"
                    />
                  : <div className="image editable border-solid h-full">
                    <Suspense fallback={<Loading />}>
                      <Video
                        videoUrl={signedValue}
                        hdVideoUrl={signedValue720}
                        allowMediaDownload={false}
                        sessionEditVideo={true}
                      />
                    </Suspense>
                    </div>
                : this.props.creatingAiContent && this.props.creatingAiImage && this.props.courseCover ? <div className="text-center skeleton-box h-[300px] w-[400px] mx-auto" />
                  :  <img
                    className="image editable border-solid" data-test="upload-img-btn"
                    src="/images/image-placeholder.jpg"
                    onClick={e => this.handlePhotoUpload(e, field)}
                    alt="Upload photo or video"
                  />
            }

            {value && !disabled && !isUploading && (
              <button
                className="absolute right-0 bottom-0 mr-4 mb-4 icon icon-trash-alt-light w-10 h-10 rounded-full bg-coral hover:bg-red-800 text-white"
                onClick={(e) => {
                  e.stopPropagation()
                  this.handlePhotoDelete(e, this.props.defaultImageUrl ? 'imageUrl' : 'videoUrl')
                }}
                aria-label={`Remove ${this.props.defaultImageUrl ? 'photo' : 'video'}`}
              />
            )}
          </div>
        </div>

        <div className={`${isColorDark(this.props.bgColor) && this.props.courseCover && 'text-zinc-50'} text-center text-sm my-6`}>
          {intl.getHTML('action_note_photo_ext')}
        </div>
      </div>
    )
  }
}

export const CoverEditable = (p) => {
  const { data: appData } = useQuery<AppState>(getAppStateQuery)
  const { data: orgData, loading: orgLoading, refetch } = useQuery<GetOrganizationsRes, GetOrganizationsVars>(getOrganizationsQuery, {
    skip: !appData,
    variables: {
      searchText: '',
      skip: 0,
      limit: 100,
      limitToUserOrganizations: appData?.appState.loggedInAs.uid || '',
      limitToManagerOrganizations: appData?.appState.loggedInAs.uid || '',
      disabled: false,
    },
    errorPolicy: 'all',
  })
  const activeWorkspaceId = useActiveWorkspaceId()

  const workspaceCanGenerateImage = orgData?.res.organizations.find(o => o.id === activeWorkspaceId)?.featureFlags.includes('generateImageAi')
  if (!appData) return <MiniLoader />

  return (
    <Editable
      disabled={false}
      target={p.target}
      defaultImageUrl={p.defaultImageUrl}
      defaultVideoUrl={p.defaultVideoUrl}
      sessionVideo={!!p.defaultVideoUrl}
      videoUrl_720p={p.videoUrl_720p}
      field={p.field}
      allowMediaDownload={p.allowMediaDownload}
      isEditing={p.isEditing}
      mimetypes={p.mimetypes}
      token={appData.appState.loggedInAs.token}
      fullWidth={p.fullWidth}
      update={p.update}
      courseId={appData.appState.currentCompanyIdCourseId.split('-')[1]}
      showAiGenerateImage={workspaceCanGenerateImage}
      organizationId={activeWorkspaceId}
      courseCover={p.courseCover}
      bgColor={p.bgColor}
      creatingAiImage={p.creatingAiImage}
      creatingAiContent={p.creatingAiContent}
    />
  )
}
