import intl from 'react-intl-universal'
import React, { useEffect, useRef, useState } from 'react'
import Slider from 'react-slick'
import { useQuery } from '@apollo/client'
import { getUserMetadata } from 'graphql/queries/user/getUser'
import { AppState, getAppStateQuery } from 'graphql/queries/app/getAppState'
import { showModal } from './utils/CustomModal'
import { BASE_API_URL } from 'constants/General'
import { Button } from './utils/Button'
import { updateMeta } from 'actions/users_actions'
import Tippy from '@tippyjs/react'
import { useWhiteLabel } from 'context/whiteLabel'
import { isColorDark } from 'utils/functions'

type LastUpdatesItemType =  {
  '_archived': boolean,
  '_draft': boolean,
  'featured': boolean,
  'post-body': string,
  'name': string,
  'slug': string,
  'main-image': {
    'fileId': string,
    'url': string,
    'alt': string,
  },
  'summary': string,
  'updated-on': string,
  'updated-by': string,
  'created-on': string,
  'created-by': string,
  'published-on': string,
  'published-by': string,
  '_cid': string,
  '_id': string,
}

const UpdatesModalContent = ({ updates, close }: { updates: LastUpdatesItemType[], close: () => void }) => {
  const _sliderRef = useRef<any>(null)
  const [slideNum, setSlideNum] = useState(0)

  useEffect(() => {
    updateMeta('/gnowbeUpdates/lastSeen', String(Date.now()), true)
  }, [])
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }
  const isLastSlide = slideNum === updates.length - 1
  return (
    <div>
      <Slider ref={_sliderRef} {...settings} afterChange={setSlideNum}>
        {updates.map((item) => {
          const imgUrl = item['main-image']?.url || '/images/product-update-img.svg'
          return (
            <div key={item._id}>
              <img src={imgUrl} className="mt-4 mx-auto rounded-md h-64" />
              <div className="text-center text-2xl mt-8 font-medium">{item.name}</div>
              <p className="my-4">
                {item['summary']} <a href={`https://www.gnowbe.com/feature-updates/${item.slug}?utm_source=gnowbeweb&utm_medium=web&utm_campaign=feature-updates`} target="_blank" rel="noopener noreferrer" className="font-medium text-coral underline">Read more</a>
              </p>
            </div>
          )
        })}
      </Slider>

      <div className="flex justify-between">
        {slideNum > 0
          ? <Button
              text={intl.get('global_previous')}
              onClick={() => _sliderRef.current && _sliderRef.current.slickPrev()}
            />
          : <div/>
        }

        <Button
          type="primary"
          text={isLastSlide ? intl.get('global_close') : intl.get('next_text')}
          onClick={() => {
            if (_sliderRef.current) {
              if (isLastSlide) {
                return close()
              }
              _sliderRef.current.slickNext()
            }
          }}
        />
      </div>
    </div>
  )
}

const FeatureUpdatesIcon = ({ onlyIcon, accentColor }: { onlyIcon?: boolean, accentColor?: string | null }) => {
  const { data: appData } = useQuery<AppState>(getAppStateQuery)

  const { data: metaData } = useQuery(getUserMetadata, {
    variables: { userId: appData?.appState.loggedInAs.uid },
  })

  const [lastUpdates, setLastUpdates] = useState<LastUpdatesItemType[]>([])

  useEffect(() => {
    search()
  }, [])

  const showUpdatesModal = () => {
    const updatesModal = showModal({
      title: intl.get('feature_update'),
      component: <UpdatesModalContent updates={lastUpdates} close={() => updatesModal.close()} />,
      onlyContent: true,
      className: 'add-action-modal',
    })
  }

  const search = async () => {
    const data = await fetch(`${BASE_API_URL}/api/v1/integrations/webflow/features?limit=3`)
    const json = await data.json()

    const items = json?.data?.items || []

    const uniqueItems = items.filter((item, index, self) => {
      return index === self.findIndex((t) => t._id === item._id)
    })
    setLastUpdates(uniqueItems)
  }

  const lastUpdate = lastUpdates.length > 0
    ? new Date(lastUpdates.sort((a, b) => new Date(b['published-on']).getTime() - new Date(a['published-on']).getTime())[0]['published-on']).getTime()
    : 0

  const lastSeenGnowbeUpdates = Number(metaData.user?.metadata?.general?.gnowbeUpdates?.lastSeen || 0)

  const notSeenUpdates = lastUpdates.filter((update) => {
    const updateDate = new Date(update['published-on']).getTime()
    return updateDate > lastSeenGnowbeUpdates
  })

  return (
    <button onClick={showUpdatesModal}>
        <div className="flex items-center">
          <Tippy content={intl.get('home_whats_new')} disabled={!onlyIcon}>
            <div className="relative">
              <svg width={onlyIcon ? '20px' : '18px'} height={onlyIcon ? '20px' : '18px'} xmlns="http://www.w3.org/2000/svg" className={`fill-gray-700 ${accentColor ? `hover:fill-[var(--accent-color)]` : 'hover:fill-coral'}`} viewBox="0 0 512 512">
                <path d="M255.9 32.11c79.47 0 151.8 41.76 192.1 109.4V48C448 39.16 455.2 32 464 32S480 39.16 480 48v128C480 184.8 472.8 192 464 192h-128C327.2 192 320 184.8 320 176S327.2 160 336 160h85.85C387.5 100.7 324.9 64 256 64C150.1 64 64 150.1 64 256s86.13 192 192 192c59.48 0 114.7-26.91 151.3-73.84c5.438-7 15.48-8.281 22.47-2.75c6.953 5.438 8.187 15.5 2.75 22.44c-42.8 54.75-107.3 86.05-176.7 86.05C132.4 479.9 32 379.5 32 256S132.4 32.11 255.9 32.11z"/>
              </svg>
              {notSeenUpdates.length > 1 && <div className="rounded-full w-2.5 h-2.5 top-0 mt-[-0.2rem] -right-1.5 bg-coral absolute"/>}
            </div>
          </Tippy>
          {!onlyIcon && <div className="ml-4">
            What's new
          </div>}
        </div>
    </button>
  )
}

export default FeatureUpdatesIcon
